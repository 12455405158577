import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  Link,
  withStyles
} from '@material-ui/core'
import { Paper, BackButton, Button } from 'src/components'
import { getToken } from 'src/utils/helper'
import _ from 'lodash'
const useStyles = makeStyles(({ palette, spacing }) => ({
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  topic: {
    fontSize: '0.9rem',
    color: palette.primary.main
    // textAlign:'justify',
    // textJustify:'inter-word'
  },
  faqMsg: {
    fontSize: '0.9rem',
    margin: spacing(2.5, 0),
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  backButton: {
    color: palette.primary.main
  },
  link: {
    wordWrap: 'break-word'
  },
  bulletlist: {
    fontSize: '0.9rem'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box)

// const msg =
//   'We’re sorry there was a missing item from your order. We can try to offer you a refund or we can ask the outlet if they can send the correct items out again. Be aware that delivery is only possible with main meals and may take between 30 and 60 minutes to arrive'
// const msgArr = msg.split('\n')

export default function FAQPageDetail(props) {
  const { history, location } = props
  const { state } = location
  const message = state.answer
  const msgArr = message.split('\n')
  const styles = useStyles()
  const [isTngMp, setIsTngMp] = useState(true)

  useEffect(() => {
    // setFaq(menuList)
    // containURL(message, setUrl)
    handleCheckPlatform()
  }, [])
  const handleCheckPlatform = () => {
    let platform = props.location.state.platform
    if (platform == 'tngmp') {
      setIsTngMp(true)
    } else {
      platform = 'ecommerce'
      setIsTngMp(false)
    }
  }

  return (
    <Box p={2}>
      {!isTngMp ? <BackButton {...props} /> : null}
      <Row my={1.5}>
        <Paper display="block" space={4} style={{ width: '75%' }}>
          <Typography variant="body1" className={styles.topic}>
            {state.topic}
          </Typography>
          {msgArr &&
            msgArr.length > 0 &&
            msgArr.map((str, idx) => {
              if (str.slice(0, 4) == 'http' || str.slice(0, 5) == 'https') {
                const url = str.replace(/\s/g, '')
                return (
                  <Link
                    key={idx}
                    href={url}
                    onClick={() => window.open(url, '_self')}
                    variant="body1"
                    className={styles.link}>
                    {str}
                  </Link>
                )
              } else if (
                str === 'You have keyed in the correct number' ||
                str ===
                  'You are using a mobile number starting with +60 (for Malaysia) or +65 (for Singapore)' ||
                str ===
                  'Your have unblocked all the 5-digits shortcode (e.g. 6xxxx) from your mobile phone’s message (SMS) App'
              ) {
                return (
                  <div key={idx} className={styles.bulletlist}>
                    <ul>
                      <li>{str}</li>
                    </ul>
                  </div>
                )
              } else {
                return (
                  <Typography
                    key={idx}
                    variant="body1"
                    className={styles.faqMsg}>
                    {str.split(' ').map((item) => {
                      if (item.startsWith('https') || item.startsWith('http')) {
                        const itemUrl = item.replace(/\s/g, '')
                        return (
                          <>
                            <Link
                              href={itemUrl}
                              onClick={() => window.open(itemUrl, '_self')}
                              variant="body1"
                              className={styles.link}>
                              {item}
                            </Link>
                            <span> </span>
                          </>
                        )
                      } else {
                        return <span>{item} </span>
                      }
                    })}
                  </Typography>
                )
              }
            })}
          {/* {state.call && (
          <Box className={styles.center} pt={5}>
            <Button title="Call Us" handleClick={() => console.log('call us')} />
          </Box>
        )} */}
        </Paper>
      </Row>
      <Row>
        {state.topic === 'How do I delete my FamilyMart membership?' &&
          !_.isEmpty(getToken()) && (
            <Button
              title={'Submit Form'}
              handleClick={() =>
                history.push({
                  pathname: `/request-deactivate`
                })
              }
            />
          )}
        {state.topic ===
          'Can I edit all personal / account information via the Mobile App?' &&
          !_.isEmpty(getToken()) && (
            <Button
              title={'Submit Form'}
              handleClick={() =>
                history.push({
                  pathname: `/request-edit`
                })
              }
            />
          )}
      </Row>
    </Box>
  )
}
