import React from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Backdrop
} from '@material-ui/core'
import {
  Paper,
  Icon,
  TextFieldInput,
  ImageInput,
  Button,
  BackButton,
  Notification,
  ParcelDetail
} from 'src/components'
import { format } from 'date-fns'
import theme from 'src/themes'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import useOrderPageLogic from '../hooks/useOrderPageLogic'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: palette.secondary.light
  },
  image: {
    width: '100%',
    height: '45%',
    zIndex: -1,
    objectFit: 'cover'
  },
  status: {
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    color: palette.primary.main,
    textTransform: 'uppercase'
  },
  icon: {
    marginLeft: 7.5,
    marginRight: 7.5,
    fontSize: '0.35rem',
    color: palette.grey[100],
    alignItems: 'center'
  },
  faq: {
    borderBottom: `2px solid ${palette.primary.light}`
  },
  parcelSupportStatementBox: {
    border: `1px solid${palette.border.light}`,
    borderRadius: '12px',
    margin: '0 10px'
  },
  parcelSupportStatement: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  topic: {
    fontWeight: 'bold',
    color: palette.grey['100']
  },
  faqMsg: {
    margin: spacing(2.5, 0),
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  backButton: {
    padding: spacing(0.5, 1.5),
    marginTop: '12px',
    marginLeft: '4px',
    position: 'absolute',
    background: 'white',
    borderRadius: '20px'
  },
  modalImgBox: {
    width: 300,
    maxHeight: 530
  },
  modalImg: {
    width: '100%',
    maxHeight: 530,
    objectFit: 'contain'
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  remove: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.warning.main,
    color: palette.common.white
  },
  backText: {
    color: palette.primary.main
  },
  box_container: {
    marginTop: '60px'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

export default function OrderPage(props) {
  const styles = useStyles()
  const { purchaseType, location, history } = props

  const { state } = location

  const {
    currTopic,
    images,
    setImages,
    data,
    setData,
    contact,
    setContact,
    isSaving,
    parentStatements,
    isChildStatement,
    isChatSupport,
    message,
    notify,
    setNotify,
    highPriority,
    handleSubmit,
    onSelectTopic,
    resetParents,
    onImageClick,
    removePreviewImg,
    resetState,
    isTngMp,
    setIsTngMp,
    showImgModal,
    setShowImgModal
  } = useOrderPageLogic(
    purchaseType,
    state,
    location.platform,
    history,
    location
  )

  return (
    <Box className={styles.root}>
      {!isChildStatement && !currTopic && !isTngMp && (
        <BackButton {...props} className={styles.backButton} />
      )}
      {isChildStatement && !isTngMp && (
        <Box className={styles.backButton} onClick={() => resetParents()}>
          <Typography variant="h3" className={styles.backText}>
            Back
          </Typography>
        </Box>
      )}
      {currTopic && !isTngMp && (
        <Box
          className={styles.backButton}
          onClick={() => resetState(parentStatements)}>
          <Typography variant="h3" className={styles.backText}>
            Back
          </Typography>
        </Box>
      )}

      <Box
        position="absolute"
        width="100%"
        display="flex"
        justifyContent="center"
        className={styles.box_container}
        mb={2}
        mt={-5}
        pb={4}>
        <Paper display="block" space={0} style={{ width: '93%' }}>
          <Box py={2}>
            <Row px={3}>
              <Typography variant="h4">
                Order No #{state.orderNumber}
              </Typography>
              {purchaseType !== 'parcel' && (
                <Row mx={1.5} mt={0.25}>
                  <Typography variant="body1">
                    {state.totalOrderItems} item[s]
                  </Typography>
                </Row>
              )}
            </Row>
            <Box mb={2} px={3}>
              <Typography variant="h4" className={styles.status}>
                {state.status}
              </Typography>
              {purchaseType === 'voucher' ? (
                <Typography variant="body1">
                  {`${format(
                    new Date(state.orderDateTime),
                    'dd MMMM y hh:mm a'
                  )}`}
                </Typography>
              ) : purchaseType === 'parcel' ? (
                <Typography variant="body1">
                  {`${format(new Date(state.orderDate), 'dd MMMM y hh:mm a')}`}
                </Typography>
              ) : (
                <Typography variant="body1">
                  {`${format(
                    new Date(state.scheduledDateTime),
                    'dd MMMM y hh:mm a'
                  )}`}
                </Typography>
              )}
            </Box>
            {/* {isChildStatement && (
              <Box display="flex" justifyContent="flex-start" px={3}>
                <ArrowBackIosIcon
                  name="back-icon"
                  style={{ color: theme.palette.primary.main, width: 18, height: 18, fontWeight: 'bold' }}
                  onClick={() => resetParents()}
                />
              </Box>
            )} */}
            {purchaseType === 'parcel' && (
              <ParcelDetail state={state} currTopic={currTopic} />
            )}
            {!currTopic &&
              parentStatements.map((item, idx) => {
                return (
                  <Row
                    key={idx}
                    py={1.5}
                    px={3}
                    className={
                      purchaseType === 'parcel'
                        ? styles.parcelSupportStatementBox
                        : styles.faq
                    }
                    onClick={() => onSelectTopic(item)}>
                    <Typography
                      variant="body1"
                      style={{ flex: 1 }}
                      className={
                        purchaseType === 'parcel' &&
                        styles.parcelSupportStatement
                      }>
                      {item.statement}
                    </Typography>
                    <Icon
                      name="next"
                      style={{ color: theme.palette.primary.main }}
                    />
                  </Row>
                )
              })}
            {currTopic && (
              <Box
                borderTop={
                  purchaseType === 'parcel'
                    ? `1px solid ${theme.palette.border.light}`
                    : `3px solid ${theme.palette.primary.light}`
                }
                pt={1.5}>
                <Row justifyContent="space-between" mx={3} mb={1.5}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {purchaseType !== 'voucher' ? currTopic : 'Voucher Order'}
                  </Typography>
                  {/* <ExpandLessIcon
                    name="expand-less"
                    style={{ color: theme.palette.primary.main, float: 'right' }}
                    onClick={() => resetState(parentStatements)}
                  /> */}
                </Row>
                {message &&
                  message.length > 0 &&
                  message.map((str, idx) => {
                    return (
                      <Box key={idx} px={3}>
                        <Typography variant="body1" className={styles.faqMsg}>
                          {str}
                        </Typography>
                      </Box>
                    )
                  })}
              </Box>
            )}
            {isChatSupport && (
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF',
                    marginLeft: 24
                  }}>
                  COMMENTS
                </Typography>
                <TextFieldInput
                  placeholder="Let us know about your order"
                  value={data.message}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                  multiline
                />
                {highPriority && (
                  <div>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginLeft: 24,
                        marginTop: 10
                      }}>
                      CONTACT
                    </Typography>
                    <TextFieldInput
                      placeholder="Please enter your phone number"
                      value={contact.message}
                      onChange={(e) =>
                        setContact({ ...contact, message: e.target.value })
                      }
                    />
                  </div>
                )}
                <Row px={3}>
                  <Typography
                    variant="h4"
                    style={{
                      color: '#1E91CF'
                    }}>
                    ATTACHMENTS
                  </Typography>
                  <Box
                    display="flex"
                    style={{ position: 'relative', top: 40, right: 90 }}>
                    {[0, 1, 2].map((i, idx) => {
                      return (
                        <ImageInput
                          setImages={setImages}
                          images={images}
                          idx={idx}
                          key={idx}
                          onImageClick={onImageClick}
                          setNotify={setNotify}
                          isTngMp={isTngMp}
                        />
                      )
                    })}
                  </Box>
                </Row>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ marginTop: 40 }}>
                  <Button
                    title="Submit"
                    handleClick={() => handleSubmit()}
                    disabled={isSaving}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      <Notification
        show={notify.show}
        severity={notify.severity}
        message={notify.message}
        onClose={() => setNotify({ show: false, severity: '', message: '' })}
      />
      <Backdrop
        open={showImgModal.show}
        onClick={() =>
          setShowImgModal({ show: false, image: '', currIdx: null })
        }
        className={styles.backdrop}>
        <Box>
          <div className={styles.modalImgBox}>
            <img src={showImgModal.image} className={styles.modalImg} />
          </div>
          <Typography
            className={styles.remove}
            onClick={() => removePreviewImg(showImgModal.currIdx)}>
            remove image
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  )
}
